import React, { useState } from "react";
import { useUiContext } from "../context/UiContext";
import { Box, Button, Divider, Typography, useTheme } from "@mui/material";
import { GreenColor } from "..";
import { ContactDialog } from "./ContactDialog";
import { ContentContainer } from "./ContentContainer";
import StarIcon from "@mui/icons-material/Star";
import { Review } from "./Review";

const itemSx = { display: "flex", gap: "24px", alignItems: "center" };
const subItemSx = {
  display: "flex",
  flexDirection: "column",
  gap: "4px",
};
const iconSx = { color: "#fff", fontSize: "32px" };

const number = Math.ceil(Math.random() * 3);

const reviews = [
  {
    text: "I was on vacation and had a dental emergency.  They got me in and were so nice!  The dentist was excellent and took time to explain treatment.  Highly recommend!",
    author: "Geri Mason",
  },
  {
    text: "Chad is great! I've gotten several checkups/cleanings done, as well as a filling and night guard. Always professional work and personable care, and he's always able to explain things well when I have questions. My hygienist and the office staff are always pleasant.",
    author: "Julie Esposito",
  },
];

export const About = () => {
  const theme = useTheme();
  const { isMobile } = useUiContext();

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        position: "relative",
      }}
    >
      <Box
        sx={{
          backgroundImage: 'url("/wide.png")',
          width: "100%",
          backgroundSize: "cover",
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "35vw",
            backgroundColor: "#fff",
            padding: "48px",
            paddingRight: "15vw",
            height: "650px",
          }}
        >
          <Typography
            variant="caption"
            sx={{ fontWeight: 900, display: "block", textAlign: "center" }}
          >
            Who we are
          </Typography>
          <Typography variant="h2" sx={{ textAlign: "center" }}>
            Elk Ridge Dentistry
          </Typography>
          <Divider sx={{ margin: "24px 0" }} />
          <Typography variant="body1" sx={{ margin: "24px 0" }}>
            Greet everyone you meet with a dazzling, healthy smile that exudes
            confidence wherever you go. Achieve that smile with the help of the
            dental care professionals from Elk Ridge Dentistry.
          </Typography>
          <Typography variant="body1">
            Our practice is conveniently located in Estes Park, CO and serves
            the neighboring communities of Allenspark, Lyons, and Grand Lake
            with comprehensive dental services. Whether you need a simple
            routine cleaning, teeth whitening, or more invasive surgery, we’ll
            do everything we can so you feel good in the smile you wear.
          </Typography>
          <Typography
            variant="caption"
            sx={{
              fontWeight: 900,
              display: "block",
              textAlign: "center",
              margin: "48px 0 18px 0",
            }}
          >
            Reviews on Google
          </Typography>
          <Box
            sx={{
              display: "flex",
              placeItems: "center",
              flexDirection: "column",
            }}
          >
            <Review author={reviews[0].author} text={reviews[0].text} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
