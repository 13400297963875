import React, { useState } from "react";
import { useUiContext } from "../context/UiContext";
import { Box, Button, Divider, Typography, useTheme } from "@mui/material";
import { GreenColor } from "..";
import { ContactDialog } from "./ContactDialog";
import { ContentContainer } from "./ContentContainer";
import StarIcon from "@mui/icons-material/Star";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";

const itemSx = { display: "flex", gap: "24px", alignItems: "center" };
const subItemSx = {
  display: "flex",
  flexDirection: "column",
  gap: "4px",
};
const iconSx = { color: "#fff", fontSize: "32px" };

const number = Math.ceil(Math.random() * 3);

export const Review = ({ text, author }: { text: string; author: string }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "90%",
        maxWidth: "450px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", gap: "18px" }}>
        <FormatQuoteIcon fontSize="large" htmlColor={GreenColor["400"]} />
        <Typography variant="body1" sx={{ paddingTop: "12px" }}>
          {text}
        </Typography>
      </Box>
      <Box sx={{ alignSelf: "flex-end", fontWeight: 700 }}>
        <Box>
          <StarIcon sx={{ color: "#FFA500", fontSize: "20px" }}></StarIcon>
          <StarIcon sx={{ color: "#FFA500", fontSize: "20px" }}></StarIcon>
          <StarIcon sx={{ color: "#FFA500", fontSize: "20px" }}></StarIcon>
          <StarIcon sx={{ color: "#FFA500", fontSize: "20px" }}></StarIcon>
          <StarIcon sx={{ color: "#FFA500", fontSize: "20px" }}></StarIcon>
        </Box>
        <Typography
          variant="body1"
          sx={{ alignSelf: "flex-end", fontWeight: 700 }}
        >
          - {author}
        </Typography>
      </Box>
    </Box>
  );
};
